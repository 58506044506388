import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusOurAddress, OurAddressEdges } from './our-address.interface';

export default (): OurAddressEdges[] => {
    const { allDirectusOurAddress } = useStaticQuery<AllDirectusOurAddress>(graphql`
    query {
      allDirectusOurAddress {
        edges {
          node {
            directusId
            slug
            title
            first_office_image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            first_office_image_alt
            first_office_title
            first_office_body
            first_office_price {
              value
            }
            second_office_image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            second_office_image_alt
            second_office_title
            second_office_body
            second_office_price {
              value
            }
          }
        }
      }
    }
  `);

    return allDirectusOurAddress.edges;
};
