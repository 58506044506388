import React from 'react';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import { Props, OfficeProps } from 'components/directus/our-address/OurAddress.interface';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import ourAddressHook from 'hooks/our-address/our-address.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './OurAddress.scss';

export default ({ slug }: Props): JSX.Element => {
    const address = getFieldBySlug(slug, ourAddressHook()).node;
    const Office = ({ image, imageAlt, locationName, address, price }: OfficeProps): JSX.Element => (
        <div className={s.grid__block}>
            <div>
                <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    alt={imageAlt}
                    className={s.image}
                    fadeIn
                />
            </div>

            <div className={s.content}>
                <h4 className={s.content__locationName}>{locationName}</h4>
                <Markdown source={address} className={s.content__address} container />
                <div className={s.content__price}>
                    <PriceWithoutVat price={price} postText="per year" />
                </div>
            </div>
        </div>
    );

    return (
        <>
            <SectionTitle
                title={address.title}
                align="center"
            />

            <div className={s.grid}>
                <Office
                    image={address.first_office_image}
                    imageAlt={address.first_office_image_alt}
                    locationName={address.first_office_title}
                    address={address.first_office_body}
                    price={address.first_office_price.value}
                />

                <Office
                    image={address.second_office_image}
                    imageAlt={address.second_office_image_alt}
                    locationName={address.second_office_title}
                    address={address.second_office_body}
                    price={address.second_office_price.value}
                />
            </div>
        </>
    );
};
